import { GetHeaders } from '@features/Common';
const headers: HeadersInit = {
  'Content-Type': 'application/json',
};
export const apibaseUrl = process.env.REACT_APP_INTERNAL_API_BASE_URL ?? '';
export const webBaseUrl = process.env.REACT_APP_KFS_URL ?? '';

interface HttpResponse<T> extends Response {
  parsedBody?: T;
}

export interface PrppResponse {
  Status: string;
  Code: string;
  Message: string;
  Results: any;
}

export interface APIResponse {
  Status: string;
  Code: string;
  Message: string;
  Results: any;
}

export async function http<T>(request: RequestInfo): Promise<HttpResponse<T>> {
  const response: HttpResponse<T> = await fetch(request);

  try {
    response.parsedBody = await response.json();
  } catch (ex) {
    throw Error;
  }

  if (!response.ok) {
    throw response.statusText;
  }
  return response;
}

export async function get<T>(path: string, args: RequestInit = { method: 'get' }): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function post<T>(
  path: string,
  body: any,
  args: RequestInit = { method: 'post', headers, body: JSON.stringify(body) }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function put<T>(
  path: string,
  body: any,
  args: RequestInit = { method: 'put', headers, body: JSON.stringify(body) }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function GetData<T>(path: string): Promise<HttpResponse<T>> {
  const actualEndpoint = apibaseUrl + path;

  const apiHeaders = await GetHeaders(actualEndpoint);
  const args: RequestInit = { method: 'get', headers: apiHeaders };
  return await http<T>(new Request(actualEndpoint, args));
}

export async function PostData<T>(path: string, body: any): Promise<HttpResponse<T>> {
  const actualEndpoint = apibaseUrl + path;

  const apiHeaders = await GetHeaders(actualEndpoint);
  const args: RequestInit = { method: 'post', headers: apiHeaders, body: JSON.stringify(body) };
  return await http<T>(new Request(actualEndpoint, args));
}
