import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BarcodeResponseModel } from 'common/models/BarcodeTypes';
import { BoxContentModel, BoxDetailsModel, ReceiverModel, SenderModel } from 'common/models/BookingTypes';
import {
  ContainerBarcodeDetailViewModel,
  ContainerDetailsViewModel,
  ContainerViewModel,
  PackingListModel,
  UploadBarcodeResponse,
} from 'common/models/ContainerTypes';
import { List } from 'reselect/es/types';
import { OperationStatus } from 'store/rootTypes';

const name = 'Container';

interface IContainerState {
  submitStatus: OperationStatus;
  ContainerList?: ContainerViewModel[];
  SelectedContainerId?: string;
  Container?: ContainerViewModel;
  ContainerDetails?: ContainerDetailsViewModel;
  ContainerDetailsList?: ContainerDetailsViewModel[];
  UploadBarcodeResponse?: UploadBarcodeResponse;
  ContainerBarcodeDetailList?: ContainerBarcodeDetailViewModel[];
  IsFromListing: boolean;
  PackingList?: PackingListModel[];
  CurrentPagingLoading: number;
}

const initialState: IContainerState = {
  submitStatus: OperationStatus.idle,
  ContainerList: undefined,
  SelectedContainerId: undefined,
  Container: undefined,
  IsFromListing: false,
  CurrentPagingLoading: 1,
};

export const ContainerSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    SetContainerList: (state, action: PayloadAction<ContainerViewModel[]>) => {
      state.ContainerList = action.payload;
    },
    SetSelectedContainerId: (state, action: PayloadAction<string>) => {
      state.SelectedContainerId = action.payload;
    },
    SetCurrentPagingLoading: (state, action: PayloadAction<number>) => {
      state.CurrentPagingLoading = action.payload;
    },
    SetContainer: (state, action: PayloadAction<ContainerViewModel | undefined>) => {
      state.Container = action.payload;
    },
    SetContainerDetailsList: (state, action: PayloadAction<ContainerDetailsViewModel[]>) => {
      state.ContainerDetailsList = action.payload;
    },
    SetContainerDetails: (state, action: PayloadAction<ContainerDetailsViewModel | undefined>) => {
      state.ContainerDetails = action.payload;
    },
    SetUploadBarcodeResponse: (state, action: PayloadAction<UploadBarcodeResponse | undefined>) => {
      state.UploadBarcodeResponse = action.payload;
    },
    SetContainerBarcodeDetailList: (state, action: PayloadAction<ContainerBarcodeDetailViewModel[]>) => {
      state.ContainerBarcodeDetailList = action.payload;
    },
    SetIsFromListing: (state, action: PayloadAction<boolean>) => {
      state.IsFromListing = action.payload;
    },
    SetPackingList: (state, action: PayloadAction<PackingListModel[]>) => {
      state.PackingList = action.payload;
    },
    submitStatus: (state) => handlePendingSubmitAction(state),
    submitStatusRejected: (state, action: PayloadAction<string>) => handleSubmitRejection(state, action),
    submitStatusSucceeded: (state) => {
      state.submitStatus = OperationStatus.succeeded;
    },
    resetSubmitStatus: (state) => {
      state.submitStatus = OperationStatus.idle;
    },
  },
});

const handlePendingSubmitAction = (state) => {
  state.submitStatus = OperationStatus.pending;
};

const handleSubmitRejection = (state, action: PayloadAction<string>) => {
  state.submitStatus = OperationStatus.failed;
  console.error(action.payload);
};

export const { actions: ContainerActions } = ContainerSlice;

export default ContainerSlice.reducer;
