import { Alert, Button, Col, Form, Modal, Row, Table } from 'antd';
import { Constants, CountryWorklocation, Roles } from 'common/constants';
import { ContainerSelector } from '../store/ContainerSelector';
import { useDispatch, useSelector } from 'react-redux';
import { OperationStatus } from 'store/rootTypes';
import { PATHS } from '@routing/routes';
import { useHistory } from 'react-router';
import TextArea from 'antd/es/input/TextArea';
import {
  ContainerBarcodeDetailViewModel,
  DeleteBarcodeRequest,
  UploadBarcodeRequest,
} from 'common/models/ContainerTypes';
import { DeleteContainerBarcode, GetContainerBarcodeDetails, LoadToContainer } from '../business/ContainerBusiness';
import { useEffect, useState } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { message } from 'antd';
import { formatContainerTimestamp } from 'common/utils/utility';
import { GetHeaders } from '@features/Common';
import { apibaseUrl } from 'common/utils/jsonFetch';
import { KFSEndpoints } from 'common/constants/KFSEndpoints';
import { GetTransactionDelivery, TransactionActions } from '@features/Transaction';
import { AccountSelector } from '@features/Account';
export const ContainerLoading = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const containerState = useSelector(ContainerSelector);
  const [deleteBarcodeRequest, setDeleteBarcodeRequest] = useState<DeleteBarcodeRequest>();
  let data: ContainerBarcodeDetailViewModel[] = containerState.ContainerBarcodeDetailList || [];
  const accountState = useSelector(AccountSelector);

  const statusOptions = Array.from(new Set(data.map((item) => item.StatusDescription))).map((status) => ({
    text: status,
    value: status,
  }));

  const referenceOptions = Array.from(new Set(data.map((item) => item.RefNo))).map((status) => ({
    text: status,
    value: status,
  }));

  useEffect(() => {
    const fetchData = async () => {
      if (containerState.SelectedContainerId) {
        await dispatch(GetContainerBarcodeDetails(containerState.SelectedContainerId));
      }
    };

    fetchData();

    data = containerState.ContainerBarcodeDetailList || [];
  }, [containerState.SelectedContainerId]);

  const handleDeleteClick = async (data: DeleteBarcodeRequest) => {
    setDeleteBarcodeRequest(data);
  };

  useEffect(() => {
    if (deleteBarcodeRequest !== undefined) {
      showConfirm();
    }
  }, [deleteBarcodeRequest]);

  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      title: 'Do you want to delete this barcode?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'No',
      cancelText: 'Yes',
      okButtonProps: { className: 'ant-btn-default-okcancelbooking' },
      cancelButtonProps: { className: 'ant-btn-primary' },
      async onCancel() {
        if (deleteBarcodeRequest) {
          await dispatch(DeleteContainerBarcode(deleteBarcodeRequest));
          if (containerState.SelectedContainerId) {
            await dispatch(GetContainerBarcodeDetails(containerState.SelectedContainerId));
          }
        } else {
          message.warning('No barcode selected. Please select');
        }
      },
      async onOk() {
        //conso
      },
    });
  };

  const generateExcel = async () => {
    try {
      const apiHeaders = await GetHeaders(apibaseUrl + KFSEndpoints.Container_GenerateExcel);
      const url1 = `${apibaseUrl + KFSEndpoints.Container_GenerateExcel}?id=${containerState.Container?.Id}`;
      const response = await fetch(url1, {
        method: 'GET',
        headers: apiHeaders,
      });
      const blob = await response.blob();
      // Create a download link and trigger a download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      const formattedDate = 'Container-' + containerState.Container?.ContainerNo + '.xlsx';
      link.setAttribute('download', formattedDate);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error generating Excel file', error);
    }
  };

  const handleReferenceClick = async (id) => {
    dispatch(TransactionActions.SetSelectedTransId(id));
    await dispatch(GetTransactionDelivery(id));
    history.push(PATHS.Delivered.path);
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'SenderName',
      key: 'SenderName',
    },
    // {
    //   title: 'Receiver',
    //   dataIndex: 'Receiver',
    //   key: 'Receiver',
    // },
    {
      title: 'Reference no',
      dataIndex: 'RefNo',
      key: 'RefNo',
      filters: referenceOptions,
      onFilter: (value: string | number | boolean, record: ContainerBarcodeDetailViewModel) => {
        if (typeof value === 'string') {
          return record.RefNo.includes(value);
        }
        return false;
      },
      render: (text, record) =>
        record.IsForDelivery ? (
          <a onClick={() => handleReferenceClick(record.TransactionId)}>{record.RefNo}</a>
        ) : (
          <span>{record.RefNo}</span>
        ),
    },
    {
      title: 'City & Province',
      dataIndex: 'CityProvince',
      key: 'CityProvince',
    },
    {
      title: 'Destination',
      dataIndex: 'Destination',
      key: 'Destination',
    },
    {
      title: 'Status',
      dataIndex: 'StatusDescription',
      key: 'StatusDescription',
      filters: statusOptions,
      onFilter: (value: string | number | boolean, record: ContainerBarcodeDetailViewModel) => {
        if (typeof value === 'string') {
          return record.StatusDescription.includes(value);
        }
        return false;
      },
    },
    {
      title: 'Barcode no',
      dataIndex: 'BarcodeNo',
      key: 'BarcodeNo',
    },
    {
      title: 'Delete',
      dataIndex: 'Id',
      key: 'Id',
      render: (text, record) =>
        record.StatusCode !== 'DE' &&
        record.StatusCode !== 'OFD' &&
        accountState.WorkLocationCountry != CountryWorklocation.PH ? (
          <a
            onClick={() =>
              handleDeleteClick({
                Id: record.Id,
                TransactionId: record.TransactionId,
              })
            }
          >
            Delete barcode
          </a>
        ) : null,
    },
  ];

  const onFinish = async (values: UploadBarcodeRequest) => {
    values.ContainerId = containerState.SelectedContainerId ?? '';
    await dispatch(LoadToContainer(values));
    if (containerState.SelectedContainerId) {
      await dispatch(GetContainerBarcodeDetails(containerState.SelectedContainerId));
    }
  };

  return (
    <div className="App">
      <Row>
        {accountState.RoleName != Roles.BackOffice && accountState.WorkLocationCountry != CountryWorklocation.PH && (
          <h1>Load to container</h1>
        )}
        {accountState.RoleName == Roles.BackOffice && accountState.WorkLocationCountry == CountryWorklocation.PH && (
          <h1>Container details</h1>
        )}
      </Row>

      <Form name="containerForm" onFinish={onFinish} form={form} layout="vertical">
        <Row>
          <Col xs={24} sm={20} md={10} lg={8} xl={4} className="textColor">
            <p>
              <b>Container no: </b> {containerState.Container?.ContainerNo}
            </p>
            <p>
              <b>Details:</b> {containerState.Container?.From} to {containerState.Container?.To}
            </p>
          </Col>
          <Col xs={24} sm={20} md={10} lg={8} xl={4} className="textColor"></Col>
          <Col xs={24} sm={20} md={10} lg={8} xl={4} className="textColor">
            <p>
              <b>ETD:</b> {formatContainerTimestamp(containerState.Container?.ETD)}
            </p>
            <p>
              <b>ATD:</b> {formatContainerTimestamp(containerState.Container?.ATD)}
            </p>
          </Col>
          <Col xs={24} sm={20} md={10} lg={8} xl={4} className="textColor">
            <p>
              <b>ETA:</b> {formatContainerTimestamp(containerState.Container?.ETA)}
            </p>
            <p>
              <b>ATA:</b> {formatContainerTimestamp(containerState.Container?.ATA)}
            </p>
          </Col>
          <Col xs={24} sm={20} md={10} lg={8} xl={4} className="textColor">
            <p>
              <b>Remarks:</b> {containerState.Container?.Remarks}
            </p>
          </Col>
        </Row>
        <hr />
        <br />
        {accountState.RoleName != Roles.BackOffice && accountState.WorkLocationCountry != CountryWorklocation.PH && (
          <>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                <Form.Item
                  label={
                    <span>
                      Insert barcode number(s) here. You can enter one or multiple barcodes.
                      <br />
                      Please follow the correct format (e.g., NSW995742S, NSW467106S). New lines are allowed.
                      <br />
                    </span>
                  }
                  name="Barcodes"
                  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                >
                  <TextArea size="large" maxLength={500} rows={5} />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                {containerState?.UploadBarcodeResponse?.IsSuccess && (
                  <>
                    <Alert message="Barcode(s) successfully uploaded." type="success" showIcon />
                    <br />
                  </>
                )}

                {containerState?.UploadBarcodeResponse?.IsSuccess == false && (
                  <>
                    <Alert message={containerState?.UploadBarcodeResponse?.Message} type="error" showIcon />
                    <br />
                  </>
                )}
              </Col>
            </Row>

            <Row gutter={[10, 0]}>
              <Col xs={24} sm={20} md={12} lg={10} xl={10}>
                <Button
                  className="prevSenderBtn"
                  size="large"
                  type="default"
                  loading={containerState.submitStatus == OperationStatus.pending}
                  onClick={() =>
                    containerState.IsFromListing
                      ? history.push(PATHS.ContainerListing.path)
                      : history.push(PATHS.ContainerProfile.path)
                  }
                >
                  {Constants.BTN_BACK}
                </Button>
                <Button
                  className="nextSenderBtn"
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={containerState.submitStatus == OperationStatus.pending}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form>
      {accountState.RoleName != Roles.BackOffice && accountState.WorkLocationCountry != CountryWorklocation.PH && (
        <>
          <br />
          <br />
          {data && data.length > 0 && (
            <Row gutter={[10, 0]}>
              <Col xs={24} sm={20} md={20} lg={20} xl={20}>
                <br />
                <br />
                <Button
                  className="prevSenderBtn downloadExceltTransaction"
                  size="middle"
                  type="primary"
                  onClick={generateExcel}
                >
                  Download
                </Button>
              </Col>
            </Row>
          )}
        </>
      )}

      <Table dataSource={data} columns={columns} pagination={{ pageSize: 50 }} />
      {accountState.RoleName == Roles.BackOffice && accountState.WorkLocationCountry == CountryWorklocation.PH && (
        <Row gutter={[10, 0]}>
          <Col xs={24} sm={20} md={12} lg={10} xl={10}>
            <Button
              className="prevSenderBtn"
              size="large"
              type="default"
              loading={containerState.submitStatus == OperationStatus.pending}
              onClick={() =>
                containerState.IsFromListing
                  ? history.push(PATHS.ContainerListing.path)
                  : history.push(PATHS.ContainerProfile.path)
              }
            >
              {Constants.BTN_BACK}
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};
