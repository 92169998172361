interface CommonDropdownCode {
  name: string;
  code: string;
}

export const CommonDropdownCodeList: CommonDropdownCode[] = [
  {
    name: 'All',
    code: 'All',
  },
  {
    name: 'New',
    code: 'NW',
  },
  {
    name: 'For Pick up',
    code: 'PI',
  },
  {
    name: 'Received - with KFS agent',
    code: 'ITRA',
  },
  {
    name: 'In transit - container',
    code: 'ITIS-ITD-ITADP',
  },
  {
    name: 'Warehouse-In',
    code: 'ITWHIPH',
  },
  {
    name: 'Out for delivery',
    code: 'OFD',
  },
  {
    name: 'For drop off - empty box',
    code: 'FD',
  },
  {
    name: 'Delivered',
    code: 'DE',
  },
];

export const StateFilterCodeList: CommonDropdownCode[] = [
  {
    name: 'All',
    code: 'All',
  },
  {
    name: 'New South Wales',
    code: 'NSW',
  },
  {
    name: 'Victoria',
    code: 'VIC',
  },
  {
    name: 'Queensland',
    code: 'QLD',
  },
  {
    name: 'South Australia',
    code: 'SA',
  },
  {
    name: 'Western Australia',
    code: 'WA',
  },
  {
    name: 'Tasmania',
    code: 'TAS',
  },
];
