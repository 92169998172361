import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Form, Row, DatePicker, Space, Table, Card, Divider, Steps, Button, Collapse, CollapseProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TransactionsHistoryViewModel } from 'common/models/BookingTypes';
import { Constants, PageSize, TransactionStatus, TransactionType } from 'common/constants';
import { OperationStatus } from 'store/rootTypes';
import { TransactionActions } from '@features/Transaction';
import { BookingActions, BookingSelector, GetCustomerTransactionsHistory } from '@features/Booking';
import React from 'react';
import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
  SolutionOutlined,
  LoadingOutlined,
  ClockCircleOutlined,
  CarOutlined,
  EnvironmentOutlined,
  FileProtectOutlined,
  FilePptOutlined,
  HomeOutlined,
  GlobalOutlined,
  InsertRowBelowOutlined,
  BankOutlined,
  RetweetOutlined,
  RadiusSettingOutlined,
  MergeCellsOutlined,
  CodeSandboxOutlined,
} from '@ant-design/icons';
import { FaTruckLoading } from 'react-icons/fa';
import { FaDocker, FaTruck } from 'react-icons/fa';
import { FaTruckMoving } from 'react-icons/fa';
import { TrackingSelector } from '../store/TrackingSelector';
import { GetTrackingStatus } from '../business/TrackingBusiness';
import { TrackingStatusResponse } from 'common/models/TrackingTypes';

export const TrackingStatus = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [trackingLabel, setTrackingLabel] = useState('');
  const trackingState = useSelector(TrackingSelector);
  let data: TrackingStatusResponse[] = trackingState.TrackingStatusResponse || [];
  useEffect(() => {
    const fetchData = async () => {
      if (trackingState.SearchString && data.length < 1) {
        await dispatch(GetTrackingStatus(trackingState.SearchString, false));
        data = trackingState.TrackingStatusResponse || [];
      }
    };

    fetchData();
    // alert(trackingState.SearchString);
  }, [trackingState.SearchString]);

  useEffect(() => {
    setTrackingLabel(data[0]?.Type == TransactionType.OrderBox ? 'Tracking - Empty box order' : 'Tracking');
  }, [data[0]?.Type]);
  const { Step } = Steps;

  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'View details',
      children: <p>{text}</p>,
    },
  ];

  return (
    <div className="App">
      <Row justify="center" align="middle">
        <h1>{trackingLabel}</h1>
      </Row>

      <Form name="senderForm" layout="vertical">
        <br />
        <Row justify="center" align="middle">
          <Col>
            <Steps direction="vertical" current={10}>
              {data.map((step, index) => {
                return (
                  <Step
                    key={index}
                    title={
                      <>
                        <span dangerouslySetInnerHTML={{ __html: step.Description }} />
                      </>
                    }
                    icon={getIcon(step.Status)}
                    // description={step.Date}
                    description={
                      <>
                        <div>{step.Date}</div>
                        {step.Status === 'PD' && (
                          <Collapse defaultActiveKey={['1']} ghost className="collapseTracking">
                            <Collapse.Panel header="View details" key="1">
                              <ul className="partiaul">
                                {step.PartialDelivery.split('|').map((item, idx) => {
                                  const details = item.split('~');
                                  return (
                                    <li key={idx}>
                                      {details.map((detail, detailIdx) => (
                                        <div
                                          key={detailIdx}
                                          className={
                                            detailIdx != 0
                                              ? 'ant-steps-item-description descriptionpartial'
                                              : 'descriptionpartial'
                                          }
                                        >
                                          {detail}
                                        </div>
                                      ))}
                                    </li>
                                  );
                                })}
                              </ul>
                            </Collapse.Panel>
                          </Collapse>
                          // <Collapse defaultActiveKey={['1']} ghost items={items} className="collapseTracking" />
                        )}
                      </>
                    }
                  />
                );
              })}
            </Steps>
            <Button
              className="prevSenderBtn full-width "
              size="large"
              type="default"
              onClick={() => history.push('/tracking')}
            >
              {Constants.BTN_BACK}
            </Button>{' '}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const getIcon = (code: string) => {
  switch (code) {
    case TransactionStatus.NW: //New
      return <UserOutlined />;
    case TransactionStatus.PI: //For Pick up	For Pick up - [location]
      return <ClockCircleOutlined />;
    case TransactionStatus.ITRA: //In-Transit	Received - with KFS agent
      return <FileProtectOutlined />;
    case TransactionStatus.ITP: //In-Transit	Processed -  [location]
      return <SolutionOutlined />;
    case TransactionStatus.ITIS: //In-Transit	- Interstate
      return <MergeCellsOutlined />;
    case TransactionStatus.ITD: //In-Transit	Departed - [location]
      return <GlobalOutlined />;
    case TransactionStatus.ITAD: //In-Transit	Arrived - [location] transhipment port
      return <InsertRowBelowOutlined />;
    case TransactionStatus.ITDDP: //In-Transit	Departed - [location] transhipment port to Manila, PH
      return <GlobalOutlined />;
    case TransactionStatus.ITADP: //In-Transit	Arrived - Manila port
      return <InsertRowBelowOutlined />;
    case TransactionStatus.ITWHIPH: //In-Transit	Arrived - Manila port
      return <BankOutlined />;
    case TransactionStatus.ITWD: //In-Transit	Warehouse to destination - estimated delivery : [date]
      return <HomeOutlined />;
    case TransactionStatus.DE || TransactionStatus.PD: //Delivered	Delivered - received by [name]
      return <CodeSandboxOutlined />;
    case TransactionStatus.CA: //Cancelled
      return <CloseCircleOutlined />;
    default:
      return <FaTruckLoading />;
  }
};
