import { APIResponseCode } from 'common/constants';
import { BarcodeGenerateRequestModel, BarcodeResponseModel } from 'common/models/BarcodeTypes';
import { APIResponse, GetData, PostData } from 'common/utils/jsonFetch';
import { KFSEndpoints } from 'common/constants/KFSEndpoints';
import { Validator } from 'common/validators/validators';
import { ChangePasswordViewModel, RegisterViewModel, ResetPasswordViewModel } from 'common/models/UserTypes';
import { AccountActions } from '../store/AccountSlice';
import { ThrowError } from 'common/utils/utility';
import { BookingActions } from '@features/Booking';
import { SenderModel } from 'common/models';
import { commonAction } from '@features/Common/store/commonSlice';
import { SenderDetailsActions } from '@features/SenderDetails';

export const AccountBusiness = (data: BarcodeGenerateRequestModel) => async (dispatch) => {
  try {
    // console.log(JSON.stringify(data));
    // const response = await PostData<APIResponse>(KFSEndpoints.BARCODE_GENERATE, data);
    // new Validator(response).NotNull();
    // new Validator(response.parsedBody).NotNull();
    // new Validator(response.parsedBody?.Status).NotNull().IsEqualsString(APIResponseCode.SUCCESS);
    // new Validator(response.parsedBody?.Results).NotNull();
    // const apiResponse: BarcodeResponseModel[] = response.parsedBody?.Results;
  } catch (error) {
    const methodName = 'GenerateBarcodeBusiness';
  }
};

export const SignUpCustomer = (data: RegisterViewModel) => async (dispatch) => {
  try {
    dispatch(AccountActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.User_Signup, data);
    new Validator(response).NotNull();
    new Validator(response.parsedBody).NotNull();
    dispatch(AccountActions.submitStatusSucceeded());
    if (response.parsedBody?.Status == APIResponseCode.ERROR) {
      return response.parsedBody?.Message;
    } else {
      return '';
    }
  } catch (error) {
    dispatch(AccountActions.submitStatusRejected('SignUpCustomer'));
    return 'error';
  }
};

export const KFSSignupCustomer = (data: SenderModel, userId?: string) => async (dispatch) => {
  try {
    if (userId) {
      data.UserId = userId;
    }
    dispatch(AccountActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.User_KFSSignupCustomer, data);
    new Validator(response).NotNull();
    new Validator(response.parsedBody).NotNull();
    dispatch(AccountActions.submitStatusSucceeded());
    if (response.parsedBody?.Status == APIResponseCode.ERROR) {
      return response.parsedBody?.Message;
    } else {
      dispatch(
        BookingActions.SetReferralResponse({
          IsFirstTransaction: response.parsedBody?.Results.IsFirstTransaction,
          IsReferred: false,
          Referrer: '',
          HasIncentiveForReferringSomeone: false,
          Referee: '',
        })
      );
      dispatch(BookingActions.SetFromSignupExistingUserId(response.parsedBody?.Results.UserId));
      dispatch(commonAction.SetGlobalSenderUserId(response.parsedBody?.Results.UserId));
      dispatch(commonAction.SetGlobalSenderId(response.parsedBody?.Results.SenderId));
      dispatch(SenderDetailsActions.SetSelectedId(response.parsedBody?.Results.SenderId));
      return '';
    }
  } catch (error) {
    dispatch(AccountActions.submitStatusRejected('SignUpCustomer'));
    return 'error';
  }
};
export const ChangePasswordBusiness = (data: ChangePasswordViewModel) => async (dispatch) => {
  try {
    dispatch(AccountActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.User_ChangePassword, data);
    new Validator(response).NotNull();
    new Validator(response.parsedBody).NotNull();
    dispatch(AccountActions.submitStatusSucceeded());
    if (response.parsedBody?.Status == APIResponseCode.ERROR) {
      return response.parsedBody?.Message;
    } else {
      return '';
    }
  } catch (error) {
    dispatch(AccountActions.submitStatusRejected('SignUpCustomer'));
    return 'error';
  }
};

export const ConfirmUser = (data: string) => async (dispatch) => {
  try {
    dispatch(AccountActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.Signup_ConfirmUser, data);
    new Validator(response).NotNull();
    new Validator(response.parsedBody).NotNull();
    dispatch(AccountActions.submitStatusSucceeded());
    return 'S';
  } catch (error) {
    dispatch(AccountActions.submitStatusRejected('SignUpCustomer'));
    return 'E';
  }
};

export const ConfirmNow = () => async (dispatch) => {
  try {
    dispatch(AccountActions.submitStatus());
    const response = await GetData<APIResponse>(KFSEndpoints.Signup_ConfirmNow);
    new Validator(response).NotNull();
    new Validator(response.parsedBody).NotNull();
    dispatch(AccountActions.submitStatusSucceeded());
    return 'S';
  } catch (error) {
    dispatch(AccountActions.submitStatusRejected('ConfirmNow'));
    return 'E';
  }
};

export const ResetPasswordBusiness = (data: string) => async (dispatch) => {
  try {
    dispatch(AccountActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.User_ResetPassword, data);
    new Validator(response).NotNull();
    new Validator(response.parsedBody).NotNull();
    dispatch(AccountActions.submitStatusSucceeded());
    return 'S';
  } catch (error) {
    dispatch(AccountActions.submitStatusRejected('SignUpCustomer'));
    return 'E';
  }
};

export const ResetPasswordLink = (data: string) => async (dispatch) => {
  try {
    dispatch(AccountActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.User_ResetPasswordLink, data);
    // console.log(JSON.stringify(response));
    new Validator(response).NotNull();
    new Validator(response.parsedBody).NotNull();
    dispatch(AccountActions.submitStatusSucceeded());
    return response.parsedBody?.Message;
  } catch (error) {
    dispatch(AccountActions.submitStatusRejected('ResetPasswordLink'));
    return '';
  }
};

export const ResetChangePassword = (data: ResetPasswordViewModel) => async (dispatch) => {
  try {
    dispatch(AccountActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.User_ResetChangePassword, data);
    new Validator(response).NotNull();
    new Validator(response.parsedBody).NotNull();
    dispatch(AccountActions.submitStatusSucceeded());
    return 'S';
  } catch (error) {
    dispatch(AccountActions.submitStatusRejected('ResetChangePassword'));
    return '';
  }
};

export const KFSSignupCheckUser = (data: string) => async (dispatch) => {
  try {
    dispatch(AccountActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.User_KFSSignupCheckUser, data);
    new Validator(response).NotNull();
    new Validator(response.parsedBody).NotNull();
    dispatch(AccountActions.submitStatusSucceeded());
    if (response.parsedBody?.Results?.UserId != '') {
      dispatch(
        BookingActions.SetReferralResponse({
          IsFirstTransaction: response.parsedBody?.Results.IsFirstTransaction,
          IsReferred: false,
          Referrer: '',
          HasIncentiveForReferringSomeone: false,
          Referee: '',
        })
      );
      dispatch(BookingActions.SetFromSignupExistingUserId(response.parsedBody?.Results.UserId));
      dispatch(commonAction.SetGlobalSenderUserId(response.parsedBody?.Results.UserId));
      return response.parsedBody?.Results?.UserId;
    }

    return undefined;
  } catch (error) {
    dispatch(AccountActions.submitStatusRejected('KFSSignupCheckUser'));
    return undefined;
  }
};
