import { Col, Row } from 'antd';
import '@features/Webpage/homepage.css';
import { PATHS, ROUTES } from '@routing/routes';
import { WebsiteFooter } from './Footer';
export const PrivacyNotice = () => {
  return (
    <>
      <Row gutter={0} align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h3>{PATHS.PrivacyNotice.displayTitle}</h3>
        </Col>
      </Row>
      <div className="bodyAboutUs">
        <Row gutter={0} align="middle" className="textColor">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="">
            <p className="mtp-0">
              At Kalinga Freight Solutions Pty Ltd (KFS), we are committed to protecting your privacy and complying with
              the Australian Privacy Principles (APPs) outlined in the Privacy Act 1988. This privacy notice outlines
              how we collect, use, and protect your personal information when you use our balikbayan box services.
            </p>
            <p>
              <b>Information we collect</b>
              <ul className="mtp-0">
                <li className="liWhyKFS">
                  Personal identification: We may collect your name, contact details, and delivery addresses to
                  facilitate our services.
                </li>
                <li className="liWhyKFS">
                  Transaction details: Information related to your transactions with KFS, including payment details, is
                  securely processed for order fulfillment.
                </li>
                <li className="liWhyKFS">
                  Communication: Any communication you have with our customer support or through our platforms may be
                  stored to provide efficient service.
                </li>
              </ul>
            </p>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <p className="mtp-0">
              <b>How we use your information</b>
              <ul className="mtp-0">
                <li className="liWhyKFS">
                  Order processing: Your personal information is used for processing and delivering your balikbayan
                  boxes, ensuring a seamless experience.
                </li>
                <li className="liWhyKFS">
                  Communication: We may contact you with updates about your shipments, promotions, and relevant service
                  information.
                </li>
                <li className="liWhyKFS">
                  Improvement of services: Aggregated data may be analysed to enhance and customise our services for a
                  better customer experience.
                </li>
              </ul>
            </p>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <p className="mtp-0">
              <b>Data Security</b>
              <ul className="mtp-0">
                <li className="liWhyKFS">
                  We prioritise the security of your information. We employ industry-standard measures to protect
                  against unauthorised access, disclosure, or alteration.
                </li>
              </ul>
            </p>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <p className="mtp-0">
              <b>Third-party disclosure</b>
              <ul className="mtp-0">
                <li className="liWhyKFS">
                  Your information is not shared with third parties unless necessary for service delivery, legal
                  compliance, or your explicit consent.
                </li>
              </ul>
            </p>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <p className="mtp-0">
              <b>Your rights</b>
              <ul className="mtp-0">
                <li className="liWhyKFS">
                  You have the right to access, correct, or delete your personal information. If you have any privacy
                  concerns, please contact our operations team at ops@kfsbox.com.
                </li>
              </ul>
            </p>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <p className="mtp-0">
              <b>Changes to privacy notice</b>
              <ul className="mtp-0">
                <li className="liWhyKFS">
                  KFS may update this privacy notice periodically. The latest version will be available on our website.
                  By using KFS services, you agree to the terms outlined in this privacy notice.
                </li>
              </ul>
            </p>
            <p>Last updated on January 19, 2024</p>
          </Col>
        </Row>
        <br />
        <Row gutter={0} align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <WebsiteFooter />
          </Col>
        </Row>
      </div>
    </>
  );
};
