import { Breadcrumb, Button, Card, Checkbox, Col, Form, Row, Typography } from 'antd';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { BookingSelector } from '../store/BookingSelector';
import { Link } from 'react-router-dom';
import { OperationStatus } from 'store/rootTypes';
import { SubmitBooking } from '../business/BookingBusiness';
import { BookingAPIRequestModel } from 'common/models/BookingTypes';
import { useEffect } from 'react';
import { Constants } from 'common/constants';
import { DestinationCodeCodeList } from 'common/code/DestinationCode';
import { SenderDetailsSelector } from '@features/SenderDetails';
import { ReceiverSelector } from '@features/Receiver';
const { Text } = Typography;

export const Summary = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const bookingState = useSelector(BookingSelector);
  const senderDetailsState = useSelector(SenderDetailsSelector);
  const receiverState = useSelector(ReceiverSelector);
  useEffect(() => {
    if (
      senderDetailsState.Sender === null ||
      senderDetailsState.Sender === undefined ||
      receiverState.Receiver === null ||
      receiverState.Receiver === undefined ||
      bookingState.BoxContent === null ||
      bookingState.BoxContent === undefined ||
      bookingState.BoxDetails === null ||
      bookingState.BoxDetails === undefined
    ) {
      history.push('/home');
    }
  }, [bookingState, history]);

  const bookingData = {
    UserId: bookingState.FromSignupExistingUserId,
    Sender: senderDetailsState.Sender,
    Receiver: receiverState.Receiver,
    BoxContent: bookingState.BoxContent,
    BoxDetails: bookingState.BoxDetails,
    Signature: bookingState.Signature,
  };

  useEffect(() => {
    if (bookingState.submitStatus === OperationStatus.succeeded) {
      history.push('/booking/confirmation');
    }

    // return () => {
    //   // Cleanup logic (if needed)
    // };
  }, [bookingState.submitStatus, history]);

  const onFinish = async (values: any) => {
    await dispatch(SubmitBooking(bookingData as BookingAPIRequestModel));
  };

  return (
    <div className="App">
      <Row justify="center" align="middle">
        {' '}
        <h1>Box pick up summary</h1>
      </Row>

      <Form name="senderForm" onFinish={onFinish} layout="vertical">
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={8}>
            <Breadcrumb
              items={[
                {
                  title: 'Sender',
                },
                {
                  title: 'Sender address',
                },
                {
                  title: 'Receiver',
                },
                {
                  title: 'Box Details',
                },

                {
                  title: 'Pick up and box details',
                },
                {
                  title: bookingState.ReferralResponse?.IsFirstTransaction ? 'Perks for you' : null,
                },
                {
                  title: 'Declaration',
                },
                {
                  title: 'Box pick up summary',
                },
              ]}
            />
            <br />
            <Form.Item>
              <Row>
                <Col span={12}>
                  <Text className="">Sender</Text>
                </Col>
                <Col span={12} className="right-text">
                  {senderDetailsState.SelectedId != undefined ? (
                    <Link to="/sender/listing">Update</Link>
                  ) : (
                    <Link to="/booking/sender">Update</Link>
                  )}
                </Col>
                <Card className="card-custom">
                  <Col span={24}>
                    <Text className="bold">
                      {senderDetailsState.Sender?.GivenName} {senderDetailsState.Sender?.Surname}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text>
                      {senderDetailsState.Sender?.Address}, {senderDetailsState.Sender?.Suburb},{' '}
                      {senderDetailsState.Sender?.State}, {senderDetailsState.Sender?.PostalCode}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text> {senderDetailsState.Sender?.Email}</Text>
                  </Col>
                  <Col span={24}>
                    <Text>
                      {senderDetailsState.Sender?.MobileCode} {senderDetailsState.Sender?.ContactNo}
                    </Text>
                  </Col>
                </Card>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row>
                <Col span={12}>
                  <Text>Receiver</Text>
                </Col>
                <Col span={12} className="right-text">
                  {receiverState.SelectedId != undefined ? (
                    <Link to="/receiver/listing">Update</Link>
                  ) : (
                    <Link to="/booking/receiver">Update</Link>
                  )}
                </Col>
                <Card className="card-custom">
                  {receiverState.SelectedId != Constants.NoSelectedReceiver ? (
                    <>
                      <Col span={24}>
                        <Text className="bold">
                          {receiverState.Receiver?.GivenName} {receiverState.Receiver?.Surname}
                        </Text>
                      </Col>
                      <Col span={24}>
                        <Text>
                          {receiverState.Receiver?.Address}, {receiverState.Receiver?.City},{' '}
                          {receiverState.Receiver?.Province}, {receiverState.Receiver?.PostalCode}
                        </Text>
                      </Col>
                      <Col span={24}>
                        <Text>
                          {' '}
                          {receiverState.Receiver?.MobileCode} {receiverState.Receiver?.ContactNo}
                        </Text>
                      </Col>{' '}
                    </>
                  ) : (
                    <>
                      <Col span={24}>
                        <Text>{Constants.NoSelectedReceiverText}</Text>
                      </Col>{' '}
                    </>
                  )}
                </Card>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row>
                <Col span={12}>
                  <Text>What’s in the box</Text>
                </Col>
                <Col span={12} className="right-text">
                  <Link to="/booking/boxcontent">Update</Link>
                </Col>
                <Card className="card-custom">
                  <Col span={24}>
                    <Text>
                      {bookingState.BoxContent?.Details && bookingState.BoxContent.Details?.length > 0 && (
                        <>
                          {bookingState.BoxContent.Details[0]}
                          {bookingState.BoxContent.Details?.slice(1).map((detail) => `, ${detail}`)}
                        </>
                      )}
                      {bookingState.BoxContent?.Details != undefined &&
                        bookingState.BoxContent?.Details?.length > 0 &&
                        bookingState.BoxContent?.Others?.length > 0 && <br />}
                      {bookingState.BoxContent?.Others}
                    </Text>
                  </Col>
                </Card>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row>
                <Col span={12}>
                  <Text>Pick up and box details</Text>
                </Col>
                <Col span={12} className="right-text">
                  <Link to="/booking/boxdetails">Update</Link>
                </Col>
                <Card className="card-custom">
                  <Col span={24}>
                    <Text>
                      {bookingState.BoxDetails?.NoOfBox}{' '}
                      {bookingState.BoxDetails?.NoOfBox && bookingState.BoxDetails?.NoOfBox > 1 ? 'boxes' : 'box'}
                      {
                        DestinationCodeCodeList.find(
                          (destination) => destination.code === bookingState.BoxDetails?.Destination
                        )?.name
                      }
                      {bookingState.BoxDetails?.Remarks && (
                        <>
                          <br />
                          {bookingState.BoxDetails.Remarks}
                          <br />
                        </>
                      )}
                    </Text>
                  </Col>
                </Card>
              </Row>
            </Form.Item>{' '}
            {bookingState.ReferralResponse?.IsFirstTransaction && (
              <Form.Item>
                <Row>
                  <Col span={12}>
                    <Text>Your perks</Text>
                  </Col>
                  <Col span={12} className="right-text">
                    <Link to="/booking/perks">Update</Link>
                  </Col>
                  <Card className="card-custom">
                    <Col span={24}>
                      <Text>$ 10 sign up bonus</Text>
                      {bookingState.ReferralResponse?.IsReferred && (
                        <>
                          <br />
                          <Text>$ 10 referral reward</Text>
                        </>
                      )}
                    </Col>
                  </Card>
                </Row>
              </Form.Item>
            )}
            {bookingState.Signature && (
              <>
                <Form.Item className="mb-0">
                  <Row>
                    <Col span={12}>
                      <Text>Sender signature</Text>
                    </Col>
                    <Col span={12} className="right-text">
                      <Link to="/booking/declaration">Update</Link>
                    </Col>
                    <Card className="card-custom">
                      <Col span={24}>
                        <img src={bookingState.Signature} alt="Sender signature" className="signatureRender" />
                      </Col>
                    </Card>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Checkbox checked className="custom-checkbox-tC">
                    I agree to the Terms and conditions.
                  </Checkbox>
                </Form.Item>
              </>
            )}
            <div>
              <Button
                loading={bookingState.submitStatus == OperationStatus.pending}
                className="prevSenderBtn"
                size="large"
                type="default"
                onClick={() => history.push('/booking/declaration')}
              >
                {Constants.BTN_BACK}
              </Button>
              <Button
                loading={bookingState.submitStatus == OperationStatus.pending}
                className="nextSenderBtn"
                size="large"
                type="primary"
                htmlType="submit"
              >
                {Constants.BTN_COMPLETE_BOOKING}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
