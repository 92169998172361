import { Card, Col, Row } from 'antd';
import '@features/Webpage/homepage.css';
import { WebsiteFooter } from './Footer';
import { KFS_Store_Bendigo1, KFS_Store_Bendigo2 } from 'common/images';
import { useHistory } from 'react-router';
import { PATHS } from '@routing/routes';
export const StorePartnerBendigo = () => {
  const history = useHistory();

  const handleMelbourne = () => {
    history.push(PATHS.KFSStorePartnerMel.path);
  };

  const handleSydney = () => {
    history.push(PATHS.KFSStorePartnerSyd.path);
  };

  const handleWarnambool = () => {
    history.push(PATHS.KFSStorePartnerWarnambool.path);
  };
  return (
    <>
      <Row gutter={0} align="middle" className="headerMainRow">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="webPageHeaderTextAboutUs">
          <p className="headertextFAQs">KFS store partners</p>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col sm={24} className="">
          <div className="">
            <a className="normal-font dInlineBlock" onClick={() => handleMelbourne()}>
              <h3 className="normalfontweight melsydlbl"> Melbourne </h3>
            </a>
            <span className="normal-font dInlineBlock verticalBar"> | </span>
            <a className="normal-font dInlineBlock underline">
              <h3 className="normalfontweight melsydlbl"> Bendigo </h3>
            </a>
            <span className="normal-font dInlineBlock verticalBar"> | </span>
            <a className="normal-font dInlineBlock" onClick={() => handleWarnambool()}>
              <h3 className="normalfontweight melsydlbl"> Warrnambool </h3>
            </a>
            <span className="normal-font dInlineBlock verticalBar"> | </span>
            <a className="normal-font dInlineBlock" onClick={() => handleSydney()}>
              <h3 className="normalfontweight melsydlbl"> Sydney</h3>
            </a>
          </div>
        </Col>
      </Row>
      <a className="textColor">Grab your KFS balikbayan boxes from our store partners near you.</a>
      <br />
      <br />
      <Row gutter={24}>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Bendigo1} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Pinoy Tambayan</h3>
              <p className="textColor mtb">320 Lyttleton Terrace, Bendigo, VIC 3550</p>
              <p className="textColor mtp-0">+61 423 254 188</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Bendigo2} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Asian Grocery</h3>
              <p className="textColor mtb">126 McCrae St, Bendigo VIC 3550</p>
              <p className="textColor mtp-0">+61 3 5444 2592</p>
            </div>
          </Card>
        </Col>
      </Row>

      <br />
      <Row gutter={0} align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <WebsiteFooter />
        </Col>
      </Row>
    </>
  );
};
