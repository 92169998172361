import { PATHS } from '@routing/routes';
import { Button, Col, Form, Row } from 'antd';
import { ContainerViewModel, PackingListModel } from 'common/models/ContainerTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ContainerSelector } from '../store/ContainerSelector';
import { GetAllContainer, GetPackingsList, SaveContainer, UpdateContainer } from '../business/ContainerBusiness';
import dayjs from 'dayjs'; // Import dayjs
import { useEffect } from 'react';
import { ContainerActions } from '../store/ContainerSlice';
import { kfsLogo } from 'common/images';
import '@features/Container/Container.css';
import { formatTwoDecimalPlaces } from 'common/utils/utility';
export const PackingList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const convertToDayjs = (date) => {
    return date ? dayjs(date) : null;
  };

  const containerState = useSelector(ContainerSelector);
  let data: PackingListModel[] = containerState.PackingList || [];
  useEffect(() => {
    const fetchData = async () => {
      if (containerState.SelectedContainerId) {
        await dispatch(GetPackingsList(containerState.SelectedContainerId));
      }
    };

    fetchData();

    data = containerState.PackingList || [];
  }, [containerState.SelectedContainerId]);

  const onFinish = async (values: ContainerViewModel) => {
    if (containerState.Container) {
      values.Id = containerState.SelectedContainerId ?? '';
      await dispatch(UpdateContainer(values));
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    } else {
      dispatch(ContainerActions.SetIsFromListing(false));
      await dispatch(SaveContainer(values));
      history.push(PATHS.ContainerLoading.path);
    }
    await dispatch(GetAllContainer());
    form.resetFields();

    // history.push(PATHS.ContainerListing.path);
  };
  const handlePrint = () => {
    window.print(); // Trigger print dialog
  };

  return (
    <div className="App">
      <Col xs={24} sm={24} md={24} lg={24} xl={24} className="no-print">
        <Row>
          {/* <h1>Packing list</h1> */}
          <Button type="default" onClick={() => history.push(PATHS.ContainerProfile.path)}>
            Back
          </Button>
          <Button type="primary" className="mg-l" onClick={handlePrint}>
            Print
          </Button>
        </Row>
      </Col>
      <Form name="containerForm" onFinish={onFinish} form={form} layout="horizontal">
        <div className="print-container">
          {containerState?.PackingList?.map((x, index) => (
            <div key={index}>
              <table className="tblpkg">
                <tbody>
                  <tr>
                    <td>
                      {' '}
                      <img src={kfsLogo} alt="logo" className="logo logo1" />
                    </td>
                    <td className="headerTd2">
                      <p className="mtb Kalinga">Kalinga Freight Solutions</p>
                      <p className="mtb"> ABN 12348888123 | Website: www.kfsbox.com | Email: info@kfsbox.com</p>
                      <p className="mtb">
                        Contact no: +61413275288 | Address: 28 Map Road, Greenvale, VIC, 3059, Australia
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Row>
                <table className="tblpkg">
                  <tbody>
                    <tr>
                      <td className="" colSpan={2}>
                        <p className="mtb center-text">
                          <b>TRACKING NUMBER:</b> {x.TrackingNo}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="borderPackingTop borderPackingRight borderPackingLeft mtb center-text bold"
                        colSpan={2}
                      >
                        SHIPPER’S EXPORT DECLARATION AND PACKING LIST
                      </td>
                    </tr>
                    <tr>
                      <td className="borderPackingTop borderPackingRight borderPackingLeft tdp width50 vertical-top">
                        <p className="mtb">
                          <b>
                            <u>Sender’s detail</u>
                          </b>
                        </p>
                        <p className="mtb">
                          <b>Name: </b>
                          {x.Sender}
                        </p>
                        <p className="mtb">
                          <b>Address: </b>
                          {x.SenderAddress}
                        </p>
                        <p className="mtb">
                          <b>Contact no:</b> {x.SenderContact}
                        </p>
                      </td>
                      <td className="borderPackingTop borderPackingRight tdp width50 vertical-top">
                        <p className="mtb">
                          <b>
                            <u> Receiver’s details</u>
                          </b>
                        </p>
                        <p className="mtb">
                          <b>Name: </b>
                          {x.Receiver}
                        </p>
                        <p className="mtb">
                          <b>Address: </b>
                          {x.ReceiverAddress}
                        </p>
                        <p className="mtb">
                          <b>Contact no:</b> {x.ReceiverContact}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="borderPackingTop borderPackingRight borderPackingLeft vertical-top tdp width50">
                        <p className="mtb">
                          <b>
                            <u className="mtb">Goods details</u>
                          </b>
                        </p>
                        <ul className="mtb">
                          {x.GoodDetails && x.GoodDetails.split('|').length > 10 ? (
                            <li className="mtb">{x.GoodDetails.replace(/\|/g, ', ')}</li>
                          ) : (
                            x.GoodDetails?.split('|')?.map(
                              (value, index) =>
                                value.trim() !== 'Others' && (
                                  <li key={index} className="mtb">
                                    {value.trim()}
                                  </li>
                                )
                            )
                          )}
                          {x.GoodOthers && <li className="mtb">Others: {x.GoodOthers}</li>}
                        </ul>
                      </td>
                      <td className="borderPackingTop borderPackingRight vertical-top tdp width50">
                        <p className="mtb">
                          <b>
                            <u className="mtb">Barcode details</u>
                          </b>
                        </p>
                        {x.Barcode && x.Barcode?.split(',').length > 10 ? (
                          <ul className="mtb">
                            <li>{x.Barcode.replace(/,/g, ', ')}</li>
                          </ul>
                        ) : (
                          <ul className="mtb">
                            {x.Barcode?.split(',')?.map((value, index) => (
                              <li key={index} className="mtb">
                                {value.trim()}
                              </li>
                            ))}
                          </ul>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="borderPackingTop borderPackingRight borderPackingLeft vertical-top tdp width50">
                        <p className="mtb">
                          <b>
                            <u>Invoice details</u>
                          </b>
                        </p>
                        <p className="mtb">Invoice no: {x.InvoiceNo}</p>
                        <p className="mtb">Invoice date: {x.PickupDate}</p>
                      </td>
                      <td className="borderPackingTop borderPackingRight vertical-top tdp width50">
                        <tr>
                          <td>Basic charge:</td>
                          <td className="pl-50">${formatTwoDecimalPlaces(x.BasicCharge)}</td>
                        </tr>
                        <tr>
                          <td>No. of box:</td>
                          <td className="pl-50">{x.UnitCount}</td>
                        </tr>
                        <tr>
                          <td>Freight charge:</td>
                          <td className="pl-50">${formatTwoDecimalPlaces(x.FreightCharges)}</td>
                        </tr>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="borderPackingTop borderPackingRight borderPackingLeft borderPackingBottom tdp"
                        colSpan={2}
                      >
                        <b>Sender’s declaration</b>
                        <p className="mtb">This is to certify:</p>
                        <ul className="mtb">
                          <li>
                            that I am the sender of the above items contained in this parcel being sent to the
                            Philippines;
                          </li>
                          <li>that the listed items are accurate; </li>
                          <li>that the items are for personal use only;</li>
                          <li>
                            that there are no undeclared, restricted, illegal, or banned items including firearms,
                            ammunition, illegal drugs, combustible goods to this shipment.
                          </li>
                        </ul>
                        <p className="mb-0 ">
                          <b> Sender’s electronic signature: </b> {x.Sender}
                          {/* <img src={x?.Signature} alt="Sender signature" className="signature mb-0 " /> */}
                        </p>
                        <b>Date: </b>
                        {x.PickupDate}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Row>
            </div>
          ))}
        </div>
      </Form>
    </div>
  );
};
