export abstract class KFSEndpoints {
  static readonly SENDER_DETAILS_GET_ALL = '/Sender/GetAll';
  static readonly SENDER_GET_BY_ID = '/Sender/Get';
  static readonly SENDER_UPDATE = '/Sender/Update';
  static readonly SENDER_SAVE = '/Sender/Save';

  static readonly RECEIVER_UPDATE = '/Receiver/Update';
  static readonly RECEIVER_SAVE = '/Receiver/Save';
  static readonly RECEIVER_DETAILS_GET_ALL = '/Receiver/GetAll';
  static readonly RECEIVER_GET_BY_ID = '/Receiver/Get';

  static readonly BARCODE_GENERATE = '/Barcode/Generate';
  static readonly BARCODE_PRINT = '/Barcode/Print';
  static readonly Barcode_Validate = '/Barcode/ValidateBarcode';
  static readonly Barcode_SaveBarcode = '/Barcode/SaveBarcode';

  static readonly User_Login = '/User/Login';

  static readonly Transaction_Save = '/Transaction/Save';
  static readonly Transaction_GetCustomerTransactionsHistory = '/Transaction/GetCustomerTransactionsHistory';
  static readonly Transaction_GetTransactionSummary = '/Transaction/GetTransactionSummary';
  static readonly Transaction_GetTransactionByRefNo = '/Transaction/GetTransactionByRefNo';
  static readonly Transaction_SaveSignature = '/Transaction/SaveSignature';
  static readonly Transaction_SearchBy = '/Transaction/SearchBy';
  static readonly Transaction_UpdateReceiver = '/Transaction/UpdateReceiver';
  static readonly Transaction_Void = '/Transaction/Void';
  static readonly Transaction_IsFirstTransaction = '/Transaction/IsFirstTransaction';
  static readonly Transaction_GenerateExcel = '/Transaction/GenerateExcel';
  static readonly Transaction_GetTransactionDelivery = '/Transaction/GetTransactionDelivery';
  static readonly Transaction_UpdateToDelivery = '/Transaction/UpdateToDelivery';

  static readonly TransactionStatusDetails_Save = '/TransactionStatusDetails/Save';
  static readonly TransactionStatusDetails_Get = '/TransactionStatusDetails/Get';

  static readonly Invoice_GetByRefId = '/Invoice/GetByRefId';
  static readonly Invoice_SaveBookingInvoice = '/Invoice/SaveBookingInvoice';
  static readonly Invoice_SendOR = '/Invoice/SendOR';

  static readonly BoxDetails_Update = '/BoxDetails/Update';

  static readonly BoxContent_Update = '/BoxContent/Update';

  static readonly User_Signup = '/User/Signup';
  static readonly User_ChangePassword = '/User/ChangePassword';
  static readonly Signup_ConfirmUser = '/Signup/ConfirmUser';
  static readonly Signup_ConfirmNow = '/Signup/ConfirmNow';
  static readonly User_ResetPassword = '/User/ResetPassword';
  static readonly User_ResetPasswordLink = '/User/ResetPasswordLink';
  static readonly User_ResetChangePassword = '/User/ResetChangePassword';
  static readonly User_KFSSignupCheckUser = '/User/KFSSignupCheckUser';
  static readonly User_KFSSignupCustomer = '/User/KFSSignupCustomer';

  static readonly Tracking_GetTrackingStatus = '/Tracking/GetTrackingStatus';
  static readonly Contact_Us = '/Common/ContactUs';
  static readonly Common_GetProvinceDeliveryArea = '/Common/GetProvinceDeliveryArea';
  static readonly Common_GetLocationDeliveryArea = '/Common/GetLocationDeliveryArea';
  static readonly Common_GetCodeSets = '/Common/GetCodeSets';
  static readonly Incentives_Refer = '/Incentives/Refer';

  static readonly OrderBox_Save = '/OrderBox/Save';
  static readonly OrderBox_Update = '/OrderBox/Update';
  static readonly OrderBox_UpdateStatus = '/OrderBox/UpdateStatus';

  static readonly Employee_GetAllAgent = '/Employee/GetAllAgent';

  static readonly Container_Save = '/Container/Save';
  static readonly Container_GetById = '/Container/GetById';
  static readonly Container_Update = '/Container/Update';
  static readonly Container_Delete = '/Container/Delete';
  static readonly Container_GetAll = '/Container/GetAll';
  static readonly Container_UploadBarcode = '/Container/UploadBarcode';
  static readonly ContainerDetails_Save = '/ContainerDetails/Save';
  static readonly ContainerDetails_Update = '/ContainerDetails/Update';
  static readonly ContainerDetails_Delete = '/ContainerDetails/DeleteContainerDetails';
  static readonly ContainerDetails_GetByContainerId = '/ContainerDetails/GetByContainerId';
  static readonly ContainerDetails_GetContainerBarcodeDetails = '/ContainerDetails/GetContainerBarcodeDetails';
  static readonly ContainerDetails_DeleteContainerBarcode = '/ContainerDetails/DeleteContainerBarcode';
  static readonly Container_GenerateExcel = '/Container/GenerateExcel';
  static readonly Container_GetPackingsList = '/Container/GetPackingsList';
  static readonly Container_UpdateOutForDelivery = '/Container/UpdateOutForDelivery';
  static readonly Container_UpdateTransactionRequest = '/Container/UpdateTransactionRequest';

  static readonly InterState_GetAll = '/InterState/GetAll';
  static readonly InterState_GetById = '/InterState/Get';
  static readonly InterState_Save = '/InterState/Save';
  static readonly InterStateDetails_Save = '/InterStateDetails/Save';
  static readonly InterStateDetails_GetInterStateDetails = '/InterStateDetails/GetInterStateDetails';
  static readonly InterStateDetails_Delete = '/InterStateDetails/Delete';
  static readonly InterState_UploadBarcode = '/InterState/UploadBarcode';
  static readonly InterState_GetInterStateBarcodeDetails = '/InterState/GetInterStateBarcodeDetails';
  static readonly InterState_DeleteBarcode = '/InterState/DeleteBarcode';
  static readonly InterState_GenerateExcel = '/InterState/GenerateExcel';
  static readonly Store_GetAll = '/Store/GetAll';
  static readonly Store_GetAllIncentives = '/Store/GetAllIncentives';
}
