import { useEffect, useState } from 'react';
import { LockOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { FaUserCircle } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Constants } from 'common/constants';
import { LoginRequestModel } from 'common/models/UserTypes';
import { LoginUser, LoginUserAccount } from '../business/AccountLoginBusiness';
import { AccountActions, AccountSelector } from '@features/Account';
import { useDispatch, useSelector } from 'react-redux';
import { PostStart } from '@features/Common';

const { Text } = Typography;

export const Login = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const accountState = useSelector(AccountSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AccountActions.resetState());
    dispatch(PostStart());
  }, []);

  const onFinish = async (values: LoginRequestModel) => {
    setLoading(true);
    setError('');
    try {
      const data = await dispatch(LoginUserAccount(values));
      if (data.toString() === 'true') {
        history.push('/home');
      } else {
        history.push('/account/accountnotconfirm');
      }
    } catch (error) {
      // Handle error if needed
      setError('Invalid user account');
    } finally {
      setLoading(false);
    }
  };

  const onValuesChange = () => {
    setError(null);
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
    >
      <Row justify="center" align="middle">
        <Col>
          <h4>Sign in to {Constants.KFS_PAGE_TITLE}</h4>
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col xs={24} sm={20} md={12} lg={8} xl={6}>
          <Form.Item
            // initialValue={'customer_vic@yahoo.com'}
            name="Username"
            rules={[
              { required: true, message: Constants.REQUIRED_FIELD },
              { type: 'email', message: Constants.ERROR_EMAIL_FORMAT },
            ]}
          >
            <Input prefix={<FaUserCircle className="site-form-item-icon" />} placeholder="Username" />
          </Form.Item>
          <Form.Item
            // initialValue={'your-password'}
            name="Password"
            rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          {error && <Text type="danger">{error}</Text>}
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button full-width" loading={loading}>
              Log in
            </Button>
          </Form.Item>

          <Row justify="center" align="middle">
            <Col>
              <Link to="/account/resetpassword" className="normal-font">
                Reset password
              </Link>
            </Col>
          </Row>
          <Row justify="center" align="middle">
            <Col>
              No account?{' '}
              <Link to="/account/signup" className="normal-font">
                Create one
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
