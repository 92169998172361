import { StoresViewModel } from 'common/models/BookingTypes';
import { notification } from 'antd';
import { GetNotificationProps } from 'common/utils/utility';
import { APIResponseCode, MessageDisplay } from 'common/constants';
import { APIResponse, PostData } from 'common/utils/jsonFetch';
import { Validator } from 'common/validators/validators';
import { KFSEndpoints } from 'common/constants/KFSEndpoints';
import { StoresActions } from '../store/StoresSlice';
import { StoreIncentivesViewModel } from 'common/models/StoreTypes';

export const GetAllStore = () => async (dispatch) => {
  try {
    dispatch(StoresActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.Store_GetAll, null);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    const dataList: StoresViewModel[] = response.parsedBody?.Results;
    dispatch(StoresActions.SetStores(dataList));
    dispatch(StoresActions.submitStatusSucceeded());
  } catch (e) {
    dispatch(StoresActions.submitStatusRejected(''));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
  }
};

export const GetAllIncentives = (request: string) => async (dispatch) => {
  try {
    dispatch(StoresActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.Store_GetAllIncentives, request);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    const dataList: StoreIncentivesViewModel[] = response.parsedBody?.Results;
    dispatch(StoresActions.SetStoreIncentivesViewModel(dataList));
    dispatch(StoresActions.submitStatusSucceeded());
  } catch (e) {
    dispatch(StoresActions.submitStatusRejected(''));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
  }
};
