import { Col, Row } from 'antd';
import '@features/Webpage/homepage.css';
import { WebsiteFooter } from './Footer';
export const AboutUs = () => {
  return (
    <>
      <Row gutter={0} align="middle" className="headerMainRow">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="webPageHeaderTextAboutUs">
          <p className="headertextAboutUs">About Kalinga Freight Solutions (KFS)</p>
        </Col>
      </Row>
      <div className="bodyAboutUs">
        <Row gutter={0} align="middle" className="textColor">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="">
            <p>
              At <b>Kalinga Freight Solutions (KFS)</b>, our name echoes our commitment rooted deeply in the Filipino
              ethos of{' '}
              <b>
                <i>“Kalinga”</i>, representing care and dedication.
              </b>
            </p>
            <p>
              <b>Driven by care.</b> Embracing the essence of &apos;Kalinga,&apos; we prioritise exceptional customer
              service. With a heartfelt dedication, we ensure that every interaction reflects the personality of care
              deeply embedded in Filipino culture.
            </p>
            <p>
              <b> Reliability redefined.</b> With extensive experience and industry expertise, we specialise in the
              timely and secure delivery of balikbayan boxes, ensuring your cherished moments reach your loved ones
              seamlessly.
            </p>
            <p>
              <b> Seamless tracking.</b> Leveraging cutting-edge technology, we offer transparent and accessible
              shipment tracking. Our clients are empowered with real-time updates, providing peace of mind throughout
              the journey.
            </p>
            <p>
              <b> Your trusted logistics partner.</b> Apart from managing balikbayan boxes, we operate as a
              comprehensive logistics solution. Partnering with us unlocks the potential to effortlessly achieve your
              business objectives.
            </p>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col
            xs={24}
            sm={12}
            md={11}
            lg={11}
            xl={11}
            className="border-visionmission border-visionmission-left padding-smlV"
          >
            <h3 className="aboutVMH1">Our Mission</h3>
            <p className="textColor aboutVM">
              At Kalinga Freight Solutions (KFS), our mission is to embody the essence of Filipino care in every service
              we provide. We are committed to delivering exceptional logistics solutions grounded in reliability and
              empowered by innovative technology.
            </p>
          </Col>
          {/* <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col> */}
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={11}
            className="border-visionmission border-visionmission-left padding-smlV"
          >
            <h3 className="aboutVMH1">Our Vision</h3>
            <p className="textColor aboutVM">
              Our vision at Kalinga Freight Solutions (KFS) is to embody unmatched reliability and care in the logistics
              industry. We aim to redefine the standard of service, leveraging cutting-edge technology to provide
              unparalleled experiences.
            </p>
            <p className="textColor">
              As we continue to evolve, we aspire to be the preferred choice for businesses and individuals seeking
              trustworthy, efficient, and caring logistics solutions, connecting families, communities, and bridging
              distances with utmost dedication.
            </p>
          </Col>
        </Row>
        <br />
      </div>
      <Row gutter={0} align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <WebsiteFooter />
        </Col>
      </Row>
    </>
  );
};
