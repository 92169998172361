import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BarcodeResponseModel } from 'common/models/BarcodeTypes';
import { BoxContentModel, BoxDetailsModel, ReceiverModel, SenderModel } from 'common/models/BookingTypes';
import { RolesModel } from 'common/models/UserTypes';
import { List } from 'reselect/es/types';
import { OperationStatus } from 'store/rootTypes';

const name = 'Account';

interface IAccountState {
  submitStatus: OperationStatus;
  RoleName?: string;
  WorkLocationCountry?: string;
  Roles?: RolesModel[];
  IsConfirm: boolean;
  ResetPasswordLink?: string;
  Username?: string;
}

const initialState: IAccountState = {
  submitStatus: OperationStatus.idle,
  RoleName: undefined,
  Roles: undefined,
  IsConfirm: true,
};

export const AccountSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    SetRoleName: (state, action: PayloadAction<string>) => {
      state.RoleName = action.payload;
    },
    SetUserName: (state, action: PayloadAction<string>) => {
      state.Username = action.payload;
    },
    SetWorkLocationCountry: (state, action: PayloadAction<string>) => {
      state.WorkLocationCountry = action.payload;
    },
    SetIsConfirm: (state, action: PayloadAction<boolean>) => {
      state.IsConfirm = action.payload;
    },
    SetRoles: (state, action: PayloadAction<RolesModel[]>) => {
      state.Roles = action.payload;
    },
    SetResetPasswordLink: (state, action: PayloadAction<string>) => {
      state.ResetPasswordLink = action.payload;
    },
    submitStatus: (state) => handlePendingSubmitAction(state),
    submitStatusRejected: (state, action: PayloadAction<string>) => handleSubmitRejection(state, action),
    submitStatusSucceeded: (state) => {
      state.submitStatus = OperationStatus.succeeded;
    },
    resetSubmitStatus: (state) => {
      state.submitStatus = OperationStatus.idle;
    },
  },
});

const handlePendingSubmitAction = (state) => {
  state.submitStatus = OperationStatus.pending;
};

const handleSubmitRejection = (state, action: PayloadAction<string>) => {
  state.submitStatus = OperationStatus.failed;
  console.error(action.payload);
};

export const { actions: AccountActions } = AccountSlice;

export default AccountSlice.reducer;
