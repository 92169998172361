import { PATHS } from '@routing/routes';
import { Button, Card, Col, DatePicker, Form, Input, Modal, Row, Select, Table } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Constants } from 'common/constants';
import { ContainerDetailsViewModel, ContainerViewModel } from 'common/models/ContainerTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ContainerSelector } from '../store/ContainerSelector';
import { OperationStatus } from 'store/rootTypes';
import {
  DeleteContainer,
  DeleteContainerDetails,
  GetAllContainer,
  GetContainerById,
  GetContainerDetailsByContainerId,
  SaveContainer,
  SaveContainerDetails,
  UpdateContainer,
  UpdateContainerDetails,
} from '../business/ContainerBusiness';
import {
  ContainerDepartedCodeList,
  ContainerDepartedToCodeList,
  ContainerDestinationCodeList,
  ContainerFromDepartureCodeList,
  ContainerTypeCodeList,
} from 'common/code/DestinationCode';
import dayjs from 'dayjs'; // Import dayjs
import { TableLocaleCustom } from '@components/Custom/CustomTable';
import { useEffect, useState } from 'react';
import { PlusCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { formatContainerTimestamp } from 'common/utils/utility';
import { ContainerActions } from '../store/ContainerSlice';

export const ContainerProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const convertToDayjs = (date) => {
    return date ? dayjs(date) : null;
  };

  const containerState = useSelector(ContainerSelector);
  let data: ContainerDetailsViewModel[] = containerState.ContainerDetailsList || [];
  useEffect(() => {
    const fetchData = async () => {
      if (containerState.SelectedContainerId) {
        await dispatch(GetContainerDetailsByContainerId(containerState.SelectedContainerId));
      }
    };

    fetchData();

    data = containerState.ContainerDetailsList || [];
  }, [containerState.SelectedContainerId]);

  const onFinish = async (values: ContainerViewModel) => {
    if (containerState.Container) {
      values.Id = containerState.SelectedContainerId ?? '';
      await dispatch(UpdateContainer(values));
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    } else {
      dispatch(ContainerActions.SetIsFromListing(false));
      await dispatch(SaveContainer(values));
      history.push(PATHS.ContainerLoading.path);
    }
    await dispatch(GetAllContainer());
    form.resetFields();

    // history.push(PATHS.ContainerListing.path);
  };

  const [hideContainerStatusFields, setHideContainerStatusFields] = useState(true);
  const [isAddContainerDetails, setIsAddContainerDetails] = useState(true);
  const [selectedContainerDetails, setSelectedContainerDetails] = useState<ContainerDetailsViewModel | null>(null);

  const onFinish2 = async (values: ContainerDetailsViewModel) => {
    values.ContainerId = selectedContainerDetails?.ContainerId ?? '';
    values.Id = selectedContainerDetails?.Id ?? '';
    if (values.Id) {
      await dispatch(UpdateContainerDetails(values));
    } else {
      values.ContainerId = containerState.SelectedContainerId ?? '';
      await dispatch(SaveContainerDetails(values));
    }
    await dispatch(GetContainerDetailsByContainerId(values.ContainerId));

    data = containerState.ContainerDetailsList || [];
    form2.resetFields();
    // setTimeout(() => {
    //   window.location.reload();
    // }, 2000);
  };

  const getContainerTypeCodeTypeName = (code) => {
    const type = ContainerTypeCodeList.find((item) => item.code === code);
    return type ? type.name : '';
  };

  const getContainerDepartedCodeListTypeName = (code) => {
    const type = ContainerDepartedCodeList.find((item) => item.code === code);
    return type ? type.name : '';
  };

  const getContainerDepartedToCodeList = (code) => {
    const type = ContainerDepartedToCodeList.find((item) => item.code === code);
    return type ? type.name : '';
  };

  const handleLoadClick = async (id) => {
    dispatch(ContainerActions.SetSelectedContainerId(id));
    dispatch(ContainerActions.SetIsFromListing(false));
    await dispatch(GetContainerById(id));
    history.push(PATHS.ContainerLoading.path);
  };
  const { confirm } = Modal;

  const showConfirm = (container: ContainerDetailsViewModel) => {
    //Shift yes or no to satisfy the req. will refactor next time
    setHideContainerStatusFields(true);
    confirm({
      title: 'Do you want to delete this container status?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'No',
      cancelText: 'Yes',
      okButtonProps: { className: 'ant-btn-default-okcancelbooking' },
      cancelButtonProps: { className: 'ant-btn-primary' },
      async onCancel() {
        await dispatch(DeleteContainerDetails(container.Id));
        await dispatch(GetContainerDetailsByContainerId(container.ContainerId));

        data = containerState.ContainerDetailsList || [];
      },
      async onOk() {
        setHideContainerStatusFields(true);
      },
    });
  };

  const showConfirmDelete = () => {
    //Shift yes or no to satisfy the req. will refactor next time
    setHideContainerStatusFields(true);
    confirm({
      title: 'Do you want to delete this container?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'No',
      cancelText: 'Yes',
      okButtonProps: { className: 'ant-btn-default-okcancelbooking' },
      cancelButtonProps: { className: 'ant-btn-primary' },
      async onCancel() {
        if (containerState?.Container?.Id) {
          await dispatch(DeleteContainer(containerState?.Container?.Id));
          history.push(PATHS.ContainerListing.path);
        }
      },
      async onOk() {
        // setHideContainerStatusFields(true);
      },
    });
  };
  const columns = [
    {
      title: 'Sequence',
      dataIndex: 'Sequence',
      key: 'Sequence',
    },
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'Type',
      render: (text, record) => getContainerTypeCodeTypeName(record.Type),
    },
    {
      title: 'From',
      dataIndex: 'From',
      key: 'From',
      render: (text, record) => getContainerDepartedCodeListTypeName(record.From),
    },
    {
      title: 'To',
      dataIndex: 'To',
      key: 'To',
      render: (text, record) => getContainerDepartedToCodeList(record.To),
    },
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
      render: (text) => formatContainerTimestamp(text),
    },
    {
      title: 'Action',
      dataIndex: 'Load',
      key: 'Load',
      render: (text, record) => (
        <span>
          <a onClick={() => handleContainerUpdateClick(record)}>Update</a> |{' '}
          <a onClick={() => showConfirm(record)}>Delete</a>
        </span>
      ),
    },
  ];

  const handleContainerUpdateClick = (container: ContainerDetailsViewModel) => {
    // Handle the update logic here
    // For example, you can set the selected container to be used in a form for updating
    setSelectedContainerDetails(container);
    form2.setFieldsValue({
      Type: container.Type,
      Sequence: container.Sequence,
      From: container.From,
      To: container.To,
      Date: convertToDayjs(container.Date),
    });
    setIsAddContainerDetails(false);
    setHideContainerStatusFields(false);
  };

  const onCancelContainer = () => {
    form2.resetFields();
    setHideContainerStatusFields(true);
    setIsAddContainerDetails(true);
  };

  const onAddContainerDetails = () => {
    form2.resetFields();
    setHideContainerStatusFields(false);
    setIsAddContainerDetails(true);
    setSelectedContainerDetails(null);
  };

  const onCancelUpdate = () => {
    history.push(PATHS.ContainerListing.path);
  };

  const sequenceExists = data.some((item) => item.Sequence !== undefined);
  return (
    <div className="App">
      <Form name="containerForm" onFinish={onFinish} form={form} layout="vertical">
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card className="card-custom">
              <Row justify="center" align="middle">
                <h1>Container profile</h1>
              </Row>

              <Row gutter={[10, 0]}>
                <Col xs={24} sm={20} md={12} lg={8} xl={5}>
                  <Form.Item
                    label="Container no"
                    name="ContainerNo"
                    initialValue={containerState.Container?.ContainerNo}
                    rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                  >
                    <Input size="large" maxLength={25} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col xs={24} sm={20} md={12} lg={8} xl={5}>
                  <Form.Item
                    label="From"
                    name="From"
                    rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                    initialValue={containerState.Container?.From}
                  >
                    <Select size="large" showSearch placeholder="Select" optionFilterProp="children">
                      {ContainerFromDepartureCodeList.map((destination) => (
                        <Select.Option key={destination.code} value={destination.code}>
                          {destination.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={20} md={12} lg={8} xl={5}>
                  <Form.Item
                    label="ETD"
                    name="ETD"
                    rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                    initialValue={convertToDayjs(containerState.Container?.ETD)}
                  >
                    <DatePicker size="large" className="full-width" placeholder="ETD" showTime={true} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={20} md={12} lg={8} xl={5}>
                  <Form.Item
                    label="ATD"
                    name="ATD"
                    // rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                    initialValue={convertToDayjs(containerState.Container?.ATD)}
                  >
                    <DatePicker size="large" className="full-width" placeholder="ATD" showTime={true} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[10, 0]}>
                <Col xs={24} sm={20} md={12} lg={8} xl={5}>
                  <Form.Item
                    label="To"
                    name="To"
                    rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                    initialValue={containerState.Container?.To}
                  >
                    <Select size="large" showSearch placeholder="Select" optionFilterProp="children">
                      {ContainerDestinationCodeList.map((destination) => (
                        <Select.Option key={destination.code} value={destination.code}>
                          {destination.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={20} md={12} lg={8} xl={5}>
                  <Form.Item
                    label="ETA"
                    name="ETA"
                    rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                    initialValue={convertToDayjs(containerState.Container?.ETA)}
                  >
                    <DatePicker size="large" className="full-width" placeholder="ETA" showTime={true} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={20} md={12} lg={8} xl={5}>
                  <Form.Item
                    label="ATA"
                    name="ATA"
                    // rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                    initialValue={containerState.Container?.ATA ? convertToDayjs(containerState.Container?.ATA) : null}
                  >
                    <DatePicker size="large" className="full-width" placeholder="ATA" showTime={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col xs={24} sm={20} md={16} lg={16} xl={8}>
                  <Form.Item label="Remarks (optional)" name="Remarks" initialValue={containerState.Container?.Remarks}>
                    <TextArea size="large" rows={4} />
                  </Form.Item>
                </Col>
              </Row>

              {data.length == 0 && containerState.Container && !containerState.Container.HasTransaction ? (
                <>
                  <a className="underline" onClick={showConfirmDelete}>
                    Delete container
                  </a>
                  <br />
                  <br />
                </>
              ) : null}
              <Row gutter={[10, 0]}>
                <Col xs={24} sm={20} md={12} lg={10} xl={10}>
                  <Button
                    className="prevSenderBtn"
                    size="large"
                    type="default"
                    loading={containerState.submitStatus == OperationStatus.pending}
                    onClick={onCancelUpdate}
                  >
                    {Constants.BTN_CLOSE}
                  </Button>
                  <Button
                    className="nextSenderBtn"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={containerState.submitStatus == OperationStatus.pending}
                  >
                    {containerState.Container ? Constants.BTN_UPDATE : Constants.BTN_SAVE}
                  </Button>
                </Col>
              </Row>
              {containerState?.SelectedContainerId && (
                <a
                  onClick={() => handleLoadClick(containerState?.SelectedContainerId)}
                  className="underline floatRight"
                >
                  Load to container
                </a>
              )}
              {data.length > 0 && containerState.Container && containerState.Container.HasTransaction ? (
                <>
                  <a className="underline floatRight mg-r " onClick={() => history.push(PATHS.PackingList.path)}>
                    Generate packing list
                  </a>
                </>
              ) : null}
            </Card>
          </Col>
        </Row>
      </Form>
      {containerState.Container && (
        <Form name="containerForm" onFinish={onFinish2} form={form2} layout="vertical">
          <br />
          <br />
          <br />
          <Card className="card-custom">
            <Row justify="center" align="middle">
              <h1>Container status</h1>
            </Row>

            <Row justify="center" align="middle">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Table dataSource={data} columns={columns} locale={TableLocaleCustom} />
              </Col>
            </Row>
            {hideContainerStatusFields && (
              <Col>
                <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => onAddContainerDetails()}>
                  Add container status
                </Button>
                <br />
                <br />
              </Col>
            )}
            {!hideContainerStatusFields && (
              <>
                <Row justify="center" align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <h1>{selectedContainerDetails?.Id ? 'Update container status' : 'Add container status'} </h1>
                    <Row gutter={[10, 0]}>
                      <Col xs={24} sm={20} md={12} lg={8} xl={5}>
                        <Form.Item
                          label="Sequence"
                          name="Sequence"
                          rules={[
                            { required: true, message: Constants.REQUIRED_FIELD },
                            {
                              validator: (_, value) => {
                                if (
                                  sequenceExists &&
                                  data.some(
                                    (item) => item.Sequence === value && item.Id != selectedContainerDetails?.Id
                                  )
                                ) {
                                  return Promise.reject('Sequence already exist');
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Select size="large" showSearch placeholder="Select" optionFilterProp="children">
                            {Array.from({ length: 10 }, (_, index) => (
                              <Select.Option key={index + 1} value={index + 1}>
                                {index + 1}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={20} md={12} lg={8} xl={5}>
                        <Form.Item
                          label="Type"
                          name="Type"
                          rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                          // initialValue={containerState.Container?.From}
                        >
                          <Select size="large" showSearch placeholder="Select" optionFilterProp="children">
                            {ContainerTypeCodeList.map((destination) => (
                              <Select.Option key={destination.code} value={destination.code}>
                                {destination.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={[10, 0]}>
                      <Col xs={24} sm={20} md={12} lg={8} xl={5}>
                        <Form.Item
                          label="From"
                          name="From"
                          rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                          // initialValue={containerState.Container?.From}
                        >
                          <Select size="large" showSearch placeholder="Select" optionFilterProp="children">
                            {ContainerDepartedCodeList.map((destination) => (
                              <Select.Option key={destination.code} value={destination.code}>
                                {destination.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={20} md={12} lg={8} xl={5}>
                        <Form.Item
                          label="To"
                          name="To"
                          rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                          // initialValue={containerState.Container?.From}
                        >
                          <Select size="large" showSearch placeholder="Select" optionFilterProp="children">
                            {ContainerDepartedToCodeList.map((destination) => (
                              <Select.Option key={destination.code} value={destination.code}>
                                {destination.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={[10, 0]}>
                      <Col xs={24} sm={20} md={12} lg={8} xl={5}>
                        <Form.Item
                          label="Date"
                          name="Date"
                          rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                        >
                          <DatePicker
                            size="large"
                            className="full-width"
                            placeholder="Date"
                            showTime={true}
                            // format="YYYY-MM-DD HH:mm:ss"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={[10, 0]}>
                      <Col xs={24} sm={20} md={12} lg={10} xl={10}>
                        <Button
                          className="prevSenderBtn"
                          size="large"
                          type="default"
                          loading={containerState.submitStatus == OperationStatus.pending}
                          onClick={onCancelContainer}
                        >
                          {Constants.BTN_CANCEL}
                        </Button>
                        <Button
                          className="nextSenderBtn"
                          size="large"
                          type="primary"
                          htmlType="submit"
                          loading={containerState.submitStatus == OperationStatus.pending}
                        >
                          {isAddContainerDetails ? Constants.BTN_SAVE : Constants.BTN_UPDATE}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
            <br />
            <a onClick={() => history.push(PATHS.ContainerListing.path)} className="underline">
              Back to container list
            </a>
          </Card>
        </Form>
      )}
    </div>
  );
};
