import { Validator } from 'common/validators/validators';
import { ContactViewModel } from '../components/ContactUs';
import { APIResponseCode } from 'common/constants';
import { APIResponse, PostData } from 'common/utils/jsonFetch';
import { KFSEndpoints } from 'common/constants/KFSEndpoints';
import { message } from 'antd';

export const ContactUsSave = (request: ContactViewModel) => {
  return async function GetSenderByIdThunk(dispatch) {
    try {
      const response = await PostData<APIResponse>(KFSEndpoints.Contact_Us, request);

      new Validator(response).NotNull();
      new Validator(response.parsedBody).NotNull();
      new Validator(response.parsedBody?.Status).NotNull().IsEqualsString(APIResponseCode.SUCCESS);
      // message.success('We have received your message.  We will respond to you shortly.');
    } catch (e) {
      message.error('An error has been encountered. Please contact us using the provided contact information below.');
    }
  };
};
