import { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, Radio, Row, Select, Space, Table } from 'antd';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { Constants, DateRangeOption, FilterType, TransactionType } from 'common/constants';
// import moment from 'moment';
import { GetTransactionByRefNo, TransactionActions, TransactionSelector, SearchBy } from '@features/Transaction/index';
import dayjs, { Dayjs } from 'dayjs'; // Import dayjs
import { FilterSearchParameters, SearchParameters, TransactionSearchResult } from 'common/models/BookingTypes';
import { OperationStatus } from 'store/rootTypes';
import { TableLocaleCustom } from '@components/Custom/CustomTable';
import { GetHeaders } from '@features/Common';
import { KFSEndpoints } from 'common/constants/KFSEndpoints';
import { apibaseUrl } from 'common/utils/jsonFetch';
import { SortOrder } from 'antd/es/table/interface';
import { PATHS } from '@routing/routes';
import { CommonDropdownCodeList, StateFilterCodeList } from 'common/code/DropdownCode';
export const Transaction = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultDate = dayjs();
  const transactionState = useSelector(TransactionSelector);
  const defaultDateFrom = dayjs().subtract(1, 'day');
  const [form] = Form.useForm();
  let data: TransactionSearchResult[] = transactionState.TransactionSearchResult || [];
  const statusOptions = Array.from(new Set(data.map((item) => item.Status))).map((status) => ({
    text: status,
    value: status,
  }));

  const columns = [
    {
      title: 'Reference no',
      dataIndex: 'RefNo',
      key: 'RefNo',
      render: (text, record) => <a onClick={() => handleReferenceClick(record.Id, record.Type)}>{text}</a>,
    },
    {
      title: 'Sender',
      dataIndex: 'Sender',
      key: 'Sender',
    },
    {
      title: 'Suburb',
      dataIndex: 'Suburb',
      key: 'Suburb',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      filters: statusOptions,
      onFilter: (value: string | number | boolean, record: TransactionSearchResult) => {
        if (typeof value === 'string') {
          return record.Status.includes(value);
        }
        return false;
      },
      sorter: (a: TransactionSearchResult, b: TransactionSearchResult) =>
        (a.Status as string).localeCompare(b.Status as string),
    },
    {
      title: 'Pickup date',
      dataIndex: 'PickupDate',
      key: 'PickupDate',
      sorter: (a: TransactionSearchResult, b: TransactionSearchResult) =>
        new Date(a.PickupDate).getTime() - new Date(b.PickupDate).getTime(),
      sortDirections: ['ascend', 'descend'] as SortOrder[],
    },
    {
      title: 'Creation date',
      dataIndex: 'CreatedDate',
      key: 'CreatedDate',
    },
  ];
  const handleReferenceClick = async (id, type) => {
    dispatch(TransactionActions.SetSelectedTransId(id));
    dispatch(TransactionActions.SetTransactionType(type));
  };

  const [dateFrom, setDateFrom] = useState<Dayjs | null>(null); // Explicitly define the type

  const disabledDateTo = (current: Dayjs | null) => {
    // Disable dates earlier than the selected date in "Date from"
    return dateFrom && current && current.isBefore(dateFrom.startOf('day'));
  };

  const handleDateFromChange = (value: Dayjs | null) => {
    setDateFrom(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (transactionState.TransactionType == TransactionType.OrderBox && transactionState.SelectedTransId) {
        history.push(PATHS.SummaryOrderBox.path);
      } else if (transactionState.SelectedTransId) {
        history.push('/transaction/summary');
      }
    };
    dispatch(TransactionActions.SetFromUpdateLinkFalse());
    fetchData();
  }, [transactionState?.TransactionType, transactionState?.SelectedTransId]);

  useEffect(() => {
    data = transactionState.TransactionSearchResult || [];
  }, [transactionState.TransactionSearchResult]);

  const [searchParams, setSearchParams] = useState<SearchParameters>({
    Email: null,
    State: null,
    PickupFrom: null,
    PickupTo: null,
    CreatedFrom: null,
    CreatedTo: null,
    Status: null,
  });

  const [tableKey, setTableKey] = useState(0);

  const onFinish = async (values: any) => {
    setTableKey((tableKey) => tableKey + 1);
    if (transactionState.FilterType === FilterType.RefNo) {
      const filterSearchParameters: FilterSearchParameters = {
        RefNo: values.RefNo,
      };
      dispatch(TransactionActions.SetFilterSearchParameters(filterSearchParameters));
      await dispatch(GetTransactionByRefNo(values.RefNo));
    } else if (transactionState.FilterType === FilterType.Email) {
      const searchParams: SearchParameters = {
        Email: values.Email,
      };
      const filterSearchParameters: FilterSearchParameters = {
        Email: values.Email,
      };
      dispatch(TransactionActions.SetFilterSearchParameters(filterSearchParameters));
      setSearchParams(searchParams);
      await dispatch(SearchBy(searchParams));
    } else if (transactionState.FilterType === FilterType.Date && values.DateType === DateRangeOption.Pickup) {
      const filterSearchParameters: FilterSearchParameters = {
        DateType: values.DateType,
        DateFrom: values.DateFrom,
        DateTo: values.DateTo,
        Status: values.Status,
        State: values.State,
      };
      dispatch(TransactionActions.SetFilterSearchParameters(filterSearchParameters));

      const searchParams: SearchParameters = {
        PickupFrom: values.DateFrom,
        PickupTo: values.DateTo,
        Status: values.Status,
        State: values.State,
      };
      setSearchParams(searchParams);
      await dispatch(SearchBy(searchParams));
    } else if (transactionState.FilterType === FilterType.Date && values.DateType === DateRangeOption.Booking) {
      const filterSearchParameters: FilterSearchParameters = {
        DateType: values.DateType,
        DateFrom: values.DateFrom,
        DateTo: values.DateTo,
        Status: values.Status,
        State: values.State,
      };
      dispatch(TransactionActions.SetFilterSearchParameters(filterSearchParameters));

      const searchParams: SearchParameters = {
        CreatedFrom: values.DateFrom,
        CreatedTo: values.DateTo,
        Status: values.Status,
        State: values.State,
      };
      setSearchParams(searchParams);
      await dispatch(SearchBy(searchParams));
    }
  };

  const convertToDayjs = (date) => {
    return date ? dayjs(date) : null;
  };

  function formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
    const day = date.getDate().toString().padStart(2, '0');

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthName = monthNames[date.getMonth()];

    return `${year}-${monthName}-${day}`;
  }

  const generateExcel = async () => {
    try {
      const apiHeaders = await GetHeaders(apibaseUrl + KFSEndpoints.Transaction_GenerateExcel);
      const response = await fetch(apibaseUrl + KFSEndpoints.Transaction_GenerateExcel, {
        method: 'POST',
        headers: apiHeaders,
        body: JSON.stringify(searchParams), //JSON.stringify(transactionState.FilterSearchParameters), // Convert your data to JSON if required
      });
      const blob = await response.blob();
      // Create a download link and trigger a download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      const currentDate = new Date();
      const formattedDate = 'Transaction-' + formatDate(currentDate) + '.xlsx';
      link.setAttribute('download', formattedDate);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error generating Excel file', error);
    }
  };

  return (
    <div className="App">
      <Row justify="center" align="middle">
        {' '}
        {transactionState.FilterType === FilterType.RefNo ? (
          <h1>Search by Transaction reference number</h1>
        ) : transactionState.FilterType === FilterType.Email ? (
          <h1>Search by Email</h1>
        ) : (
          <h1>Search by date, status, state</h1>
        )}
      </Row>
      <Form name="senderForm" onFinish={onFinish} layout="vertical">
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={16} lg={8} xl={8}>
            {transactionState.FilterType === FilterType.RefNo && (
              <Form.Item
                label="Reference No"
                name="RefNo"
                initialValue={transactionState.FilterSearchParameters?.RefNo}
                rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
              >
                <Input size="large" maxLength={150} />
              </Form.Item>
            )}
            {transactionState.FilterType === FilterType.Email && (
              <Form.Item
                label="Email Address"
                name="Email"
                rules={[
                  { required: true, message: Constants.REQUIRED_FIELD },
                  {
                    pattern: Constants.EMAIL_REGEX_INPUT,
                    message: 'Please enter a valid email address.',
                  },
                ]}
                initialValue={transactionState.FilterSearchParameters?.Email}
              >
                <Input size="large" maxLength={150} />
              </Form.Item>
            )}
            {transactionState.FilterType === FilterType.Date && (
              <div>
                <Form.Item
                  label="Date type"
                  name="DateType"
                  initialValue={transactionState.FilterSearchParameters?.DateType || DateRangeOption.Booking}
                  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                >
                  <Radio.Group className="pl-10">
                    <Space direction="vertical">
                      <Radio value={DateRangeOption.Pickup}>Pick up date</Radio>
                      <Radio value={DateRangeOption.Booking}>Booking date</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Row gutter={[10, 0]}>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                      initialValue={
                        convertToDayjs(transactionState.FilterSearchParameters?.DateFrom) || defaultDateFrom
                      }
                      label="Date from"
                      name="DateFrom"
                      rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                    >
                      <DatePicker
                        size="large"
                        className="full-width"
                        placeholder="Date from"
                        onChange={handleDateFromChange}
                        // Set to present date
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                      initialValue={convertToDayjs(transactionState.FilterSearchParameters?.DateTo) || defaultDate}
                      label="Date to"
                      name="DateTo"
                      rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                    >
                      <DatePicker size="large" className="full-width" placeholder="Date to" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[10, 0]}>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                      label="Status"
                      name="Status"
                      initialValue={transactionState?.FilterSearchParameters?.Status ?? 'NW'}
                    >
                      <Select size="large" showSearch placeholder="Select status" optionFilterProp="children">
                        {CommonDropdownCodeList.map((eq) => (
                          <Select.Option key={eq.code} value={eq.code}>
                            {eq.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                      label="State"
                      name="State"
                      initialValue={transactionState?.FilterSearchParameters?.State ?? 'VIC'}
                    >
                      <Select size="large" showSearch placeholder="Select state" optionFilterProp="children">
                        {StateFilterCodeList.map((eq) => (
                          <Select.Option key={eq.code} value={eq.code}>
                            {eq.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}
            {transactionState.FilterType === FilterType.RefNo && (
              <div>
                <Button className="prevSenderBtn" size="large" type="default" onClick={() => history.push('/home')}>
                  {Constants.BTN_CLOSE}
                </Button>
                <Button
                  className="nextSenderBtn"
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={transactionState.submitStatus == OperationStatus.pending}
                >
                  View details
                </Button>
              </div>
            )}

            {transactionState.FilterType === FilterType.Email && (
              <div>
                <Button className="prevSenderBtn" size="large" type="default" onClick={() => history.push('/home')}>
                  {Constants.BTN_CLOSE}
                </Button>
                <Button
                  className="nextSenderBtn"
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={transactionState.submitStatus == OperationStatus.pending}
                >
                  Search
                </Button>
              </div>
            )}

            {transactionState.FilterType === FilterType.Date && (
              <div>
                <Button className="prevSenderBtn" size="large" type="default" onClick={() => history.push('/home')}>
                  {Constants.BTN_CLOSE}
                </Button>
                <Button
                  className="nextSenderBtn"
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={transactionState.submitStatus == OperationStatus.pending}
                >
                  Search
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Form>
      {transactionState.FilterType != FilterType.RefNo && (
        <>
          <br />
          {data && data.length > 0 && (
            <Button
              className="prevSenderBtn downloadExceltTransaction"
              size="middle"
              type="primary"
              onClick={generateExcel}
            >
              Download
            </Button>
          )}
          <Table key={tableKey} dataSource={data} columns={columns} locale={TableLocaleCustom} />
        </>
      )}
    </div>
  );
};
