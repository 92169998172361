import { Col, Row } from 'antd';
import '@features/Webpage/homepage.css';
import { WebsiteFooter } from './Footer';
export const FAQs = () => {
  return (
    <>
      <Row gutter={0} align="middle" className="headerMainRow">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="webPageHeaderTextAboutUs">
          <p className="headertextFAQs">Frequently asked questions</p>
        </Col>
      </Row>
      <div className="bodyFAQs">
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={11}>
            <p className="textColorPrimary mtb-0">1. How much is the insurance coverage per box?</p>
            <ul className="mtp-0">
              <li className="textColor">
                Each box is insured for $100. If you wish to insure the box based on its declared value, an additional
                10% of the declared value will be charged.
              </li>
            </ul>
            <p className="textColorPrimary mtb-0">2. How often does KFS ship containers out from Australia?</p>
            <ul className="mtp-0">
              <li className="textColor">
                We ship once a month every 3rd or 4th week of the month that originates from Melbourne.
              </li>
            </ul>
            <p className="textColorPrimary mtb-0">3. What items are not considered acceptable for shipping?</p>
            <Row className="mtb-0">
              <Col xs={24} sm={12} md={24} lg={12} xl={8}>
                <ul className="mtp-0 mtb-0">
                  <li className="textColor">Currencies</li>
                  <li className="textColor">Money orders</li>
                  <li className="textColor">Traveller’s cheque</li>
                  <li className="textColor">Jewelries</li>
                </ul>
              </Col>
              <Col xs={24} sm={12} md={24} lg={12} xl={8}>
                <ul className="mtp-0 mtb-0">
                  <li className="textColor">Prohibited drugs</li>
                  <li className="textColor">Perishables</li>
                  <li className="textColor">Firearms / ammunitions</li>
                  <li className="textColor">Explosives / toy guns</li>
                </ul>
              </Col>
            </Row>
            <p className="textColorPrimary mtb-0">
              4. What is the expected arrival time for the box at its destination?
            </p>
            <ul className="textColor mtp-0 mtb-0">
              <li>
                Starting from the date the container departs Australia, the estimated delivery times are as follows:
              </li>
              - 5 to 6 weeks for Metro Manila <br />
              - 7 to 8 weeks for Luzon <br />- 8 to 9 weeks for Visayas and Mindanao (within city limits).
            </ul>
          </Col>
          {/* <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col> */}
          <Col xs={24} sm={24} md={24} lg={24} xl={11}>
            <p className="textColorPrimary mtb-0">5. Is KFS capable of shipping items with non-standard dimensions?</p>
            <ul className="textColor mtp-0">
              <li className="textColor">
                We can ship items in their original packaging, known as custom boxes. Simply provide the dimensions and
                a description of the item for a customised quotation.
              </li>
            </ul>
            <p className="textColorPrimary mtb-0">6. Does KFS impose any weight restrictions on its boxes?</p>
            <ul className="textColor mtp-0">
              <li className="textColor">
                While we don&apos;t enforce a weight limit on customer boxes, KFS advises our customers to exercise
                sound judgment when packing a box. This precaution is taken to guarantee the safety of our team members.
                The sender should be ready to assist if necessary.
              </li>
            </ul>
            <p className="textColorPrimary mtb-0">7. Are packaging services available for us?</p>
            <ul className="textColor mtp-0">
              <li className="textColor">
                Yes, we offer efficient packaging services to secure your items during transit. Our skilled team ensures
                careful handling and uses quality materials for protection. If you have specific preferences, let us
                know, and we&apos;ll tailor our services accordingly.
              </li>
            </ul>
          </Col>
        </Row>
        <br />
      </div>
      <Row gutter={0} align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <WebsiteFooter />
        </Col>
      </Row>
    </>
  );
};
