import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EmployeeViewModel } from 'common/models';
import { OperationStatus } from 'store/rootTypes';

const name = 'Employee';

interface IEmployeeState {
  submitStatus: OperationStatus;
  Employee?: EmployeeViewModel[];
}

const initialState: IEmployeeState = {
  submitStatus: OperationStatus.idle,
};

export const EmployeeSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    SetEmployee: (state, action: PayloadAction<EmployeeViewModel[]>) => {
      state.Employee = action.payload;
    },
    submitStatus: (state) => handlePendingSubmitAction(state),
    submitStatusRejected: (state, action: PayloadAction<string>) => handleSubmitRejection(state, action),
    submitStatusSucceeded: (state) => {
      state.submitStatus = OperationStatus.succeeded;
    },
    submitStatusSucceededNoPayload: (state) => {
      state.submitStatus = OperationStatus.succeeded;
    },
    resetSubmitStatus: (state) => {
      state.submitStatus = OperationStatus.idle;
    },
  },
});

const handlePendingSubmitAction = (state) => {
  state.submitStatus = OperationStatus.pending;
};

const handleSubmitRejection = (state, action: PayloadAction<string>) => {
  state.submitStatus = OperationStatus.failed;
  console.error(action.payload);
};

export const { actions: EmployeeActions } = EmployeeSlice;

export default EmployeeSlice.reducer;
